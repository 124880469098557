@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
@import "./variables.scss";

html.tsk {
  .tsk-page-grid {
    color: black;
    margin: 1.5em;
    box-shadow: inset -0.5em 0.5em 2.5em 0em lighten($light-grey, 50);
    background: $white;
    flex-shrink: 0;
    box-shadow: 13px 15px 59px 8px rgba(0, 0, 0, 0.25);
    font-size: var(--default_font_size);
    line-height: 1;

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }

    .tsk-cell-preview {
      width: 100%;
      height: 100%;

      * {
        pointer-events: none;
      }

    }

    * {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    ::-webkit-scrollbar {
      display: none;
    }

    .react-grid-item {
      overflow: hidden;
    }

    fieldset {
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-block-start: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-block-end: 0;
      min-inline-size: 0;
    }

    input:not([type=radio]):not([type=range]) {
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .tsku-language-selector {
      text-transform: uppercase;
    }

    .tsku-language-selector-rectangle {
      border-radius: 0.5em;
      padding: 0.8em 1em;
    }

    .tsku-language-selector-circle {
      border-radius: 2em;
      width: 2em;
      height: 2em;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .tsk-flag-circle {
      border-radius: 2em;
      width: 2em;
      height: 2em;
      display: inline-block;
    }

    .tsku-form-gender {
      font-size: 0.8em;
    }

    .tsku-form-gender div.radio {
      display: inline-block;
    }

    .tsku-form > div {
      position: relative;
      width: -webkit-fill-available;
    }

    .tsku-form .tsku-form-bottom {
      padding-top: 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 1em;
    }

    .tsku-form fieldset > div {
      margin: 0.1em;
    }

    .tsku-tab-menu {
      display: flex;
      justify-content: space-around;
      height: 100%;
      align-items: center;
    }


    div.form-group.field.field-string.tsku-form-gender {
      width: 100%;
      margin-bottom: 0.3em;
    }

    .btn {
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      white-space: nowrap;
      padding: 0.5em 0.9em;
      border-radius: 0.5em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .tsku-form input {
      font-size: 1.2em;
    }

    .tsku-form .error-detail,
    .tsku-form .errors {
      display: none;
    }

    .tsku-nodisplay {
      display: none;
    }

    .tsk-placeholder-cell-preview {
      width: 100%;
      height: 100%;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.3em;
      position: absolute;
    }

  }

  .react-grid-item {
    * {
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    box-sizing: border-box;

    &.current {
      border: 3px solid $primary-color;
      border-radius: 5px;
    }

  }

  .tsk-page-edit-container {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .tsk-page-editor {
    margin-left: 2em;
    flex-grow: 1;
    height: auto;
    border-radius: 30px;
    background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
    box-shadow: 13px 15px 59px 8px rgba(0, 0, 0, 0.25);

    .tsk-page-editor-head {
      padding: 1em;
      display: flex;
      justify-content: space-around;
    }

    .tsk-page-editor-content {
      padding: 2em;
    }
  }

  .tsk-delete-icon.tsk-cell {
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 2px;
  }

  .tsk-create-page {
    margin-top: 2em;
  }

  .tsk-toolbox-button {
    height: 7em;
    width: 7em;
    margin: 1em;
    border-radius: 10px;
    background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%) !important;

    .tsk-toolbox-icon {
      font-size: 4em;
    }

    span {
      font-size: 1em;
    }
  }

  .tsk-imagecell-preview {
    img {
      max-width: 10em;
      max-height: 10em;
    }
  }

  .tsk-textcell-rte-content {
    letter-spacing: var(--tsk-txtcelleditor-letterspacing);
    line-height: var(--tsk-txtcelleditor-lineheight);
    height: 15em;
    padding-bottom: 20px;
    text-shadow: 1px 1px 1px transparentize($dark-grey, 0.5),
                2px 2px 2px transparentize($dark-grey, 0.66);

    div[data-block=true] {
      margin: 0;
    }
  }

  .tsk-input-line {
    margin-top: 0.3em;
    display: flex;
    align-items: center;
  }

  .tsk-setting-section {
    margin-top: 0.8em;

    li {
      margin-left: 1em;

      input[type="radio"] {
        margin-right: 0.5em;
      }
    }
  }


  .tsk-setting-list {
    padding-inline: 30px;
    list-style: inherit;

    .tsk-setting-list-new {
      list-style: none;
      margin-block-start: 0.7em;
      margin-inline-start: -20px;
    }
  }

  .tsk-videocell-preview {
    video {
      max-width: 10em;
      max-height: 10em;
    }
  }

  .tsk-category-list {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
  }
}

.DraftEditor-editorContainer{
  color: black;
}

.EditorToolbar__root___3_Aqz{
  color: black;
}

label{
  color: white !important;
}

.tsk-label-black{
  color: black !important;
}