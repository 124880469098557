.tsk-tabmenu-items-container {
  display: table;

  .tsk-tabmenu-item-line {
    display:table-row;

    .tsk-tabmenu-item-content {
      display:table-cell;
      padding-right: 1em;
    }
  }
}
