html.tsk {
  .tsk-grow {
    flex-grow: 1;
  }

  .tsk-topbar-content {
    display: flex;
    justify-content: space-between;

    .tsk-link {
      margin-left: 3em;
    }
  }
}
