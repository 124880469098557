@import "./variables.scss";

html.tsk {
  .tsk-projects-list-container {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .tsk-projects-list-item {
    border-radius: 10px;
    background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
    box-shadow: 8px 8px 33px 1px rgba(0, 0, 0, 0.50);
    margin: 1.5em;
    color: $white;
    height: 6em;

    a {
      display: flex;
      height: 6em;
      width: 12em;
      align-items: center;
      justify-content: center;
    }
  }
}
