a {
  text-decoration: none;
  color: inherit;
}

body{
  transition: filter 0.3s ease-in-out;
}

.blur {
  filter: blur(5px);
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: 0;
}

input:not([type=radio]):not([type=range]):not([type=checkbox]) {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="radio"]{
  height: 0.8em;
  width: 0.8em;
}

.tsku-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.tsku-modal {
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

div.tsku-modal .tsku-language-list {
  padding: 1em;
  display: flex;
  align-items: center;
}

.tsku-language-selector {
  text-transform: uppercase;
}

.tsku-language-selector-rectangle {
  border-radius: 0.5em;
  padding: 0.8em 1em;
}

.tsku-language-selector-circle {
  border-radius: 2em;
  width: 2em;
  height: 2em;
  align-items: center;
  justify-content: center;
  display: flex;
}

.tsk-flag-circle {
  border-radius: 2em;
  width: 2em;
  height: 2em;
  display: inline-block;
}

.tsku-form .checkbox {
  font-size: 0.8em;
  display: flex;
  align-items: center;
}

.checkbox label {
  display: flex;
  flex: 1;
}

.checkbox label input[type="checkbox"] {
  flex: 1;
}

.checkbox label span {
  flex: 9;
  font-size: 1.4em;
  margin-top: 0.2em;
}

.tsku-form-gender {
  text-align: center;
  font-size: 1.2em;
}

.tsku-form-gender div.radio {
  display: inline-block;
}

.tsku-form > div {
  position: relative;
  width: -webkit-fill-available;
}

.tsku-form .tsku-form-bottom {
  padding-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1em;
  font-size: 1.2em;
  border-radius: 0.2em;
}

.tsku-form fieldset > div {
  margin: auto;
  margin-top: 0.2em;
  width: 100%;
}


div.form-group.field.field-string.tsku-form-gender {
  width: 100%;
  margin-bottom: 0.3em;
  font-size: 1.2em;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 0.5em 0.9em;
  border-radius: 0.5em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tsku-form input {
  font-size: 1.2em;
  width: 100%;
}

.tsku-form .error-detail,
.tsku-form .errors {
  display: none;
}

.tsku-nodisplay {
  display: none;
}

.micro-slider {
  height: 100%;
  position: relative;
  width: 100%;
}

.slider-wrapper {
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 0 50%;
}

.slider-item {
  text-align: center;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
}

.slider-item img {
  box-shadow: 0 1px 3px rgba(0,0,0,.06), 0 1px 2px rgba(0,0,0,.12);
  vertical-align: middle;
}

.slider-item:not(.active) {
  cursor: pointer;
}

.slider-item:not(.active) * {
  pointer-events: none;
}

.indicators {
  bottom: 0.4em;
  list-style-type: none;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.indicators li {
  color: #FFF;
  float: left;
  height: 0.8em;
  margin-right: 0.4em;
  text-align: center;
  width: 0.8em;
}

.indicators li:last-child {
  margin: 0;
}

.indicators a {
  background: #FFF;
  border-radius: 0.4em;
  border: 1px solid #E6E9EC;
  color: #FFF;
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
}

.indicators .active a {
  background: #bec1b4;
}

.arrow {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.arrow.left {
  left: 1em;
}

.arrow.right {
  right: 1em;
}

.tsku-tab-menu {
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

ul.tsku-products-list-cell {
  overflow-y: auto;
  height: 100%;
}

/* width */
ul.tsku-products-list-cell::-webkit-scrollbar {
  display: initial;
  width: 0.8em;
}

/* Track */
ul.tsku-products-list-cell::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.3em;
}

/* Handle */
ul.tsku-products-list-cell::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.3em;
}

/*!* Handle on hover *!*/
/*ul.tsku-products-list-cell::-webkit-scrollbar-thumb:hover {*/
/*  background: #555;*/
/*}*/

ul.tsku-products-list-cell li.tsku-product {
  background: #bec1b4;
  margin: 0.5em;
  padding: 1em;
  display: flex;
  border-radius: 0.5em;
  position: relative;
  font-size: 1.2em;
}

ul.tsku-products-list-cell li.tsku-product .tsku-product-img {
  width: 8em;
  height: 8em;
  margin-right: 1em;
  flex-grow: 0;
  flex-shrink: 0;
}

ul.tsku-products-list-cell li.tsku-product .tsku-product-content {
  flex-grow: 1;
}

ul.tsku-products-list-cell li.tsku-product .tsku-product-content h2.tsku-product-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1em;
}

ul.tsku-products-list-cell li.tsku-product.tsku-product-name_only .tsku-product-content h2.tsku-product-title {
  margin-top: -0.25em;
  margin-bottom: -0.25em;
}

ul.tsku-products-list-cell li.tsku-product .tsku-product-content .tsku-product-description {
  text-align: justify;
}

.tsku-modal.tsku-product-details-modal {
  height: 85%;
  background: #FFF;
  width: 80%;
  border-radius: 1em;
  display: flex;
  justify-content: center;
}

.tsku-product-details-content {
  margin-top: 2em;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.tsku-background-image {
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
}

.tsku-product-large-img {
  width: 28em;
  height: 28em;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
}

.tsku-product-large-img img {
  border-radius: 1em;
}

.tsku-product-details-content h2 {
  font-size: 2em;
  line-height: 2.5em;
  flex-grow: 0;
  flex-shrink: 0;
}

.tsku-product-long-description {
  text-align: justify;
  flex-grow: 1;
  overflow-y: auto;
}

/* width */
.tsku-product-long-description::-webkit-scrollbar {
  display: initial;
  width: 0.8em;
}

/* Track */
.tsku-product-long-description::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.3em;
}

/* Handle */
.tsku-product-long-description::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.3em;
}

.tsku-modal-close {
  position: absolute;
  top: 5.5%;
  right: 5.5%;
  border-radius: 1em;
  border: 1px solid #000;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tsku-modal-container div.tsku-modal-close svg {
  width: 1.5em;
  height: 1.5em;
}

.tsku-product-small-button {
  text-align: center;
  background: rgba(0, 0, 0, 0.34);
  color: white;
  border-radius: 1em;
  font-size: 1.1em;
  height: 0.4em;
  width: 0.4em;
  display: inline-block;
  position: relative;
  margin-right: 0.2em;
  line-height: 0.55em;
  padding: 0.25em 0.4em 0.35em 0.2em;
}

ul.tsku-products-list-cell li.tsku-product .tsku-product-content h2.tsku-product-title .tsku-product-price {
  margin-left: 1.5em;
  flex-basis: 10%;
  flex-grow: 1;
  text-align: right;
}

.tsku-tab-menu .tsku-tab-menu-item {
  height: 85%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding-left: 0.5em;
  padding-right: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tsku-tab-menu .tsku-tab-menu-item.tsku-tab-menu-item-border-left {
  border-left: 1px solid;
}

.tsku-background {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
}

.tsku-background--is-fixed {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.tsku-wheel-img {
  width: 100%;
  height: auto;
  transition-duration: 12s !important;
}

.tsku-rotate {
  animation: animationWheel 12s infinite linear;
}

.btn-info{
  border-radius: 0.2em;
  font-size: 1.2em;
}

@keyframes animationWheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tsku-form-multiple_choices{
  margin-bottom: 1.2em !important;
  margin-top: 1.2em !important;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.tsku-btn-wheel:hover{
  opacity: 0.6;
  cursor: pointer;
}