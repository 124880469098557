@import "./variables.scss";

html.tsk {
  .tsk-project-edit-container {
    display: flex;
    height: 100%;
    flex-grow: 1;
  }

  .tsk-project-structure {
    color: white;
    padding-left: 3em;
    width: 24%;
    height: auto;
    border-radius: 30px;
    background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
    box-shadow: 13px 15px 59px 8px rgba(0, 0, 0, 0.25);
    margin: 20px;

    .tsk-title {
      font-weight: bold;
      margin-bottom: 0.4em;
    }

    .tsk-current {
      font-weight: bold;
    }
  }

  .tsk-project-content {
    flex-grow: 1;
  }

  .tsk-page-list-item {
    background: white;
    margin-top: 10px;
    padding: 10px;
    border-radius: 6px;
    margin-right: 20px;
    color: black;

    .tsk-main-page-button {
      visibility: hidden;
    }

    &:hover .tsk-main-page-button {
      visibility: visible;
    }

  }

  .tsk-update-ul{
    display: grid !important;
  }
}