.micro-slider {
  height: 100%;
  position: relative;
  width: 100%;

  ul.indicators {
    bottom: 0.4em;
    list-style-type: none;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;

    li {
      color: #FFF;
      float: left;
      height: 0.8em;
      margin-right: 0.4em;
      text-align: center;
      width: 0.8em;

      &:last-child {
        margin: 0;
      }
    }

    a {
      background: #FFF;
      border-radius: 0.4em;
      border: 1px solid #E6E9EC;
      color: #FFF;
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
    }

    .active a {
      background: #E6E9EC;
    }

  }

  .slider-wrapper {
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 0 50%;

    .slider-item {
      img {
        box-shadow: 0 1px 3px rgba(0,0,0,.06), 0 1px 2px rgba(0,0,0,.12);
        vertical-align: middle;
      }
      text-align: center;
      display: none;
      left: 0;
      position: absolute;
      top: 0;

      &:not(.active) {
        * {
          pointer-events: none;
        }
        cursor: pointer;
      }
    }
  }
}
