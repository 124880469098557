@import "./variables.scss";

html.tsk {
  
    .tsk-displays-list-item {
      flex-wrap: auto;
      color: white;
      display: flex;
      padding: 16px;
    }

    .tsk-display-col-item{
      flex: 1;
    }

    .tsk-dispays-list-container{
      margin-top: 40px;
      width: 100%;
    }

    .tsk-displays-row:nth-child(odd){
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .bold{
    font-weight: bold;
  }
  
  .cursor-pointor{
    cursor: pointer;
    user-select: none;
  }

  .tsk-input-search{
    padding: 6px;
    border-radius: 8px;
    width: 60%;
    font-size: 1.4em;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 14px;
  }