@import "./variables.scss";

.tsk {
  display: inline-block;
}

.tsk-colorpicker-block {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  border-width: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.tsk-color {
  display: block;
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.tsk-colorpicker-popover {
  position: relative;
  z-index: 2;
}

.tsk-colorpicker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
