.trsk-container{
    min-width: 80% !important;
    margin: auto;
}

.tsk-input-prize{
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    font-size: 1.2em;
}

.trsk-edit-card{
    padding: 40px;
    background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
    border-radius: 20px;
    border: solid 2px black;
    box-shadow: rgba(0, 0, 0, 0.4) 12px 12px 12px;
    width: 100%;
}

.tsk-mt{
    margin-top: 40px;
}

.tsk-select{
    font-size: 1.2em;
}

.tsk-option{
    font-size: 1.2em;
}

.tsk-select-prize{
    font-size: 1.4em;
    border-radius: 0.4em;
    font-weight: bold;
}

.tsk-link-prize{
    font-size: 2em;
    text-decoration: underline;
}

.tsk-link-prize:hover{
    color: rgba(255, 255, 255, 0.8);
}

.periodes-container{
    padding: 20px;
}

.tsk-periode-editor{
    background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
    margin-top: 40px;
    padding: 40px;
    border-radius: 20px;
    border: solid 2px black;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.tsk-input{
    border: none;
    padding: 4px;
    border-radius: 0.4em;
    outline: none;
    margin-top: 0.4em;
    width: 100%;
}

.tsk-label{
    margin-top: 2em;
}

.tsk-button-delete{
    padding: 10px;
    font-size: large;
    border-radius: 10px;
    background: red;
    color: white;
    border: none;
    outline: none;
    margin: 10px;
}

.tsk-br-green{
    border: solid 2px green;
}

.tsk-br-red{
    border: solid 2px red;
}