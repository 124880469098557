@import "./variables.scss";

html.tsk {
  .tsk-layout {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .tsk-topbar {
      color: $white;
      border-radius: 0px 0px 30px 30px;
      background: linear-gradient(90deg, #987AE0 0.09%, #E653A4 99.9%);
      height: 5em;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      > div {
        display: flex;
        align-items: center;
      }

      .tsk-topbar-title {
        font-size: 1.5em;
        display: flex;
        align-items: center;
        padding-left: 2em;
        height: 100%;
        width: 15%;
      }

      .tsk-topbar-content {
        padding-left: 2em;
        padding-right: 2em;
        flex-grow: 1;
      }

    }

    #tsk-main-logo {
      background: $dark-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10em;
    }

    #tsk-sidebar {
      background: $dark-grey;
      color: $white;
      width: 15em;
      flex-shrink: 0; /*makes sure that content is not cut off in a smaller browser window*/

      ul {
        display: contents;

        ul {
          list-style-type: circle;
          list-style-position: inside;
        }
      }
    }

    #tsk-main {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: #394263;
    }

    .tsk-container {
      overflow-y: auto; /*adds scroll to this container*/
      height: 100%;
      display: flex;
      color: white;
    }
    .tsk-btn-delete{
      background: red;
      color: white;
      padding: 12px;
      border-radius: 12px;
      outline: none;
      border: none;
      font-size: 1em;
    }
    .tsk-btn-delete:hover{
      background: rgb(255, 50, 50);
      cursor: pointer;
    }
  }
}
