@import './variables.scss';

.tsk-display-management-row{
    padding: 20px;
    box-sizing: border-box;
    margin-top: 50px; 
}

.tsk-container-display-edit{
    margin: 50px;
}

.tsk-input-lines{
    padding: 8px;
    font-size: 24px;
    border-radius: 8px;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.4);
}

.tsk-btn-update{
    padding: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
    color: white;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.4);
    font-size: 20px;
    border: none;
    outline: none;
}

.tsk-btn-update:hover{
    cursor: pointer;
    transform: scale(1.2);
}

.tsk-btn-update:active{
    scale: 0.8;
}