@import "./variables.scss";

html.tsk {
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  background: $background;

  body {
    height: 100%;
    overflow: hidden;  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
    margin: 0px;  /*removes default style*/
    display: flex;  /*enables flex content for its children*/
    box-sizing: border-box;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-block: 0;
    padding-inline: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  #root {
    width: 100%;
  }

  .tsk-upper {
    text-transform: uppercase;
  }

  .tsk-bullet-point {
    margin-right: 0.4em;
  }

  button.tsk-button {
    border: none;
    color: $white;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
    background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);

    &.tsk-warning {
      background: $error;
      &:hover {
        background: lighten($error, 20);
      }

      &:disabled {
        background: darken($error, 4);
        &:hover {
          background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
        }
      }

    }

    &:hover {
      background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
      text-decoration: none;
    }

    &:disabled {
      background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
      color: white;
      cursor: not-allowed;
      &:hover {
        background: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
      }
    }

    &.current {
      color: black;
      background: $white;
      border: linear-gradient(90deg, #987AE0 0%, #E653A4 100%);
    }

    &.tsk-link {
      border: none;
      font-size: inherit;
      background: inherit;
      padding: 0;
      color: inherit;
      text-decoration: inherit;
    }

    &.tsk-small {
      font-size: inherit;
      padding: 0.1em 0.5em;
      border-radius: 7px;
    }
  }

  label {
    text-transform: uppercase;
    color: $light-grey;
    margin-right: 1em;
  }

  .tsk-dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);

    .tsk-dialog {
      background: white;
      color: black !important;
      padding: 1em;
      border-radius: 10px;

      .tsk-dialog-content {
        margin-bottom: 2em;
      }

      .tsk-dialog-buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  input[type="radio"] {
    margin: 0;
  }

  .tsk-bottom-buttons {
    margin-top: 2em;
  }

  .tsk-settings-block {
    margin-bottom: 2em;
  }

  .tsk-error {
    color: $error;
    font-weight: bold;
  }

  .tsk-login-box {
    margin: 2em;
  }

  .tsk-long-select {
    min-width: 250px;
    color: black;
  }

  .tsk-link-icon {
    cursor: pointer;
  }

  .tsk-delete-icon {
    border-radius: 3px;
    padding: 2px;
    cursor: pointer;
    background: $primary-color;
    color: $white;

    &.tsk-image {
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }
}
