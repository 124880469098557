@import "./variables.scss";

html.tsk {
  .tsk-tabmenu-list-container {
    flex-direction: column;

    .tsk-tabmenu-list-item {
      background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
      padding: 10px;
      border: solid 2px black;
      border-radius: 0.8em;
      margin: 1.5em;
      color: $dark-grey;

      .tsk-tabmenu-list-content {
        display: flex;
        padding: 0.8em;
        align-items: center;
        justify-content: flex-start;

        .tsk-input-line {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          color: white;
        }

        .tsk-tabmenu-item-list-container {
          margin-left: 1em;
          margin-right: 2em;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          li {
            margin-left: 1em;
          }
        }

        .tsk-tabmenu-item-list-add {
          display: flex;
        }
      }
    }
  }
}
