@import "./variables.scss";

html.tsk {
  .tsk-product-lists-editor-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .tsk-product-list-container {
      height: 100%;
      background: #394263;
      flex-grow: 1;
      overflow-y: auto;
      
      .tsk-product-default-language {
        color: $lighter-grey;
      }
      
      .tsk-product-new-button {
        width: 6em;
        margin-left: 1em;
      }
      
      .tsk-product-list-item {
        background: white;
        color: black !important;
        box-shadow: rgba(0, 0, 0, 0.4) 10px 10px 10px;
        border: 2px black solid;
        border-radius: 0.8em;
        margin: 0.5em 1.5em;
        color: $dark-grey;
        padding: 0.8em;
        
        .tsk-product-edit-container {
          padding: 10px;
          margin-top: -0.5em;
          margin-bottom: -0.8em;
          display: flex;
          min-width: 450px;
          justify-content: space-between;
          flex-wrap: wrap;
          
          .tsk-product-edit-img img {
            width: 5em;
            height: 5em;
            margin-right: 1em;
          }
          
          .tsk-product-edit-content {
            margin-right: 1em;
            flex-grow: 1;
            
            .tsk-product-edit-title {
              display: flex;
              justify-content: space-between;
              font-weight: bold;
              margin-bottom: 1em;
            }
            
          }
          
          .tsk-product-edit-button {
            height: auto;
            margin-top: auto;
            margin-bottom: auto;
          }
          
        }
        
        .tsk-product-content {
          display: flex;
          flex-wrap: wrap;
          
          > * {
            margin-left: 5px;
            margin-right: 5px;
          }
          
          .tsk-richtexteditor {
            color: black;
            width: 500px;
          }
          
          .tsk-category-list {
            max-height: 7.9em;
            min-width: 11em;
            
            label {
              text-transform: none;
              display: block;
              font-size: 0.9em;
            }
          }
          
          .tsk-product-save-container {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .tsk-input-line {
            justify-content: space-between;
          }
          
          .tsk-imagecell-preview img {
            max-height: 7.5em;
          }
          
          .tsk-input-block {
            display: flex;
            flex-direction: column;
            label {
              text-transform: none;
              display: block;
              font-size: 0.9em;
            }
            input {
              line-height: 3em;
            }
            .tsk-whole-line {
              align-items: stretch;
            }
          }
          
          .tsk-multi-input-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          
          .tsk-textcell-rte-content {
            height: 3.5em;
          }
        }
      }
      
      .tsk-product-list-item--is-hidden {
        background: linear-gradient(90deg, #394263 0%, #242F4B 100%);
        color: white;
      }
    }
  }
}
