@import "./variables.scss";

html.tsk {
  .tsk-project-creation-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    > div {
      margin: 2em;
    }

    .tsk-form-content {
      flex-grow: 1;
      color: white;
    }

    .tsk-form-bottom {
      justify-content: flex-end;
      display: flex;
    }
  }
}
